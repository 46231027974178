import { useQuery } from "react-query";
import { endpoints } from "utils/endpoints";
import http from "utils/http";

const fetchDashboard = () => {
  const newEndpoints = endpoints.auth.dashboard;
  return http().get(newEndpoints);
};

export function useDashboard(id) {
  return useQuery(["fetchDashboard", id], () => fetchDashboard());
}
