import Layout from "components/layout";
import DataProvider from "Context/DataContext";
import { navigate } from "gatsby";
import React, { useContext, useEffect } from "react";
import SideBar from "./sideBar";

export default function UserLayout({ children }) {

    const { LocalObject } = useContext(DataProvider);
    useEffect(() => {
      const checkLogin = LocalObject.localStorage.getItem("access_token");
      if(!checkLogin){
        navigate("/user/login")
      }
    }, [LocalObject]);
    return (
      <Layout>
        <div className="wrappeR w-full  flex p-[50px] bg-midBlue">
          <div className="left bg-darkBlue w-[20%]">
            <SideBar />
          </div>
          <div className="right w-[80%]">{children}</div>
        </div>
      </Layout>
    );
}
