import DataProvider from "Context/DataContext";
import { navigate } from "gatsby";
import React, { useContext, useEffect, useState } from "react";

function SideBar() {

  const  [showName, setShowName ] = useState(' ');


  const { LocalObject } = useContext(DataProvider);

  const handleLogout = ()=>{
    LocalObject.localStorage.removeItem("access_token")
    LocalObject.localStorage.removeItem("email_id")
    LocalObject.localStorage.removeItem("email")
    LocalObject.localStorage.removeItem("acc_info")
    return navigate("/user/login");
  }
  let userName = " ";
  useEffect(() => {
      const userInfo = JSON.parse(LocalObject.localStorage.getItem("acc_info"));
      userName = userInfo?.user?.username;
      setShowName(userName)
  }, []);


  return (
    
    <div className="">
      <ul className="text-2xl font-semibold font-secondary  text-white  flex flex-col gap-y-8 p-8">
      <li className="border-b-2 pb-2 border-gray"> Welcome <br /> <span className="text-orange max-w-full block break-words">{showName}</span></li>
        <li className=" hover:text-lightBlue"> <a href="/user/dashboard">My Projects</a> </li>
        <li className=" hover:text-lightBlue"> <a href="/user/dashboard/old-projects">My Old Projects</a> </li>
        <li className=" hover:text-lightBlue"> <a href="/user/dashboard/change-password">Reset Password</a> </li>
        <li className=" hover:text-lightBlue" onClick={handleLogout}><a href="">Logout</a></li>   
      </ul>
    </div>
  );
}

export default SideBar;
