import { useDashboard } from "hooks/Auth/useDashboard";
import React from "react";

function MyProjects() {
  const { data } = useDashboard();

  return (
    <div className="">
      <section className="bg-white">
        <div className="containeR w-full">
          <div className="flex flex-wrap -ml-4">
            <div className="w-full pl-4">
              <div className="max-w-full overflow-x-auto">
                <table className="table-auto w-full">
                  <thead>
                    <tr className="bg-primary text-center">
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-black py-4 lg:py-7 px-3 lg:px-4 border-l-0">
                        Project Name
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-black py-4 lg:py-7 px-3 lg:px-4">
                        Source Language
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-black py-4 lg:py-7 px-3 lg:px-4">
                        Target Language
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-black py-4 lg:py-7 px-3 lg:px-4">
                        Amount
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-black py-4 lg:py-7 px-3 lg:px-4">
                        Order No.
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-black py-4 lg:py-7 px-3 lg:px-4">
                        Order Date
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-black py-4 lg:py-7 px-3 lg:px-4">
                        Delivery Date
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-black py-4 lg:py-7 px-3 lg:px-4">
                        Status
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-black py-4 lg:py-7 px-3 lg:px-4">
                        Download files
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                            {item.project_name ? item.project_name : "no data"}
                          </td>

                          <td className="text-center text-dark font-medium text-base py-5 px-2 bg-white border-b border-[#E8E8E8]">
                            {item.source_language
                              ? item.source_language
                              : "no data"}
                          </td>
                          <td className="text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                            {item?.quote_items
                              ? item?.quote_items.map((i) => {
                                  return i.target_language + ",";
                                })
                              : "no data"}
                          </td>
                          <td className="text-center text-dark font-medium text-base py-5 px-2 bg-white border-b border-[#E8E8E8]">
                            {item.final_price_after_tax
                              ? item.final_price_after_tax
                              : "no data"}
                          </td>
                          <td className="text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                            {item.order_number ? item.order_number : "no data"}
                          </td>
                          <td className="text-center text-dark font-medium text-base py-5 px-2 bg-white border-b border-[#E8E8E8]">
                            {item.invoice_date ? item.invoice_date : "no data"}
                          </td>
                          <td className="text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                            {item.delivery_date
                              ? item.delivery_date
                              : "no data"}
                          </td>
                          <td className="text-center text-dark font-medium text-base py-5 px-2 bg-white border-b border-[#E8E8E8]">
                            {item.status ? item.status : "no data"}
                          </td>
                          <td className="text-center text-dark font-medium text-[14px] py-5 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                            {item?.quote_items
                              ? item?.quote_items.map((i) => {
                                  return (
                                    <a
                                      target="_blank"
                                      href={
                                        i.translated_files_url_new
                                          ? i.translated_files_url_new[0]
                                              ?.presigned_link
                                          : null
                                      }
                                      className={`${
                                        i.translated_files_url_new
                                          ? "inline-block"
                                          : "hidden"
                                      } py-2 px-3 bg-darkMist hover:bg-drakGray rounded border-drakGray`}
                                    >
                                      Download
                                    </a>
                                  );
                                })
                              : "No Data"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyProjects;
